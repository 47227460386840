<template>
  <div v-if="home === 'easyTermos'" class="">
    <login-box-e-t></login-box-e-t>
  </div>
  <div v-else class="-mt-8 min-h-screen fixed z-50">
    <login-box-c-o></login-box-c-o>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoginBoxET from '@/components/Home/HomeET/LoginBox.vue';
import LoginBoxCO from '@/components/Home/HomeCO/LoginBox.vue';

export default {
  name: 'Login',
  components: { LoginBoxET, LoginBoxCO },
  metaInfo: {
    title: 'Login',
    meta: [
      {
        name: 'description',
        content: 'Login with your account.',
      },
    ],
  },
  mounted() {
    if (this.$route.query.messageKey) {
      this.handleAuthMessageKey({ messageKey: this.$route.query.messageKey });
    }
  },
  data: () => ({
    home: '',
  }),
  created: function () {
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';
  },
  methods: {
    ...mapActions('auth', ['handleAuthMessageKey']),
  },
};
</script>
