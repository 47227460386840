<template>
  <div>
    <div class="fixed inset-0 z-50 overflow-y-auto bg-white">
      <div class="fixed inset-0 transition-opacity">
        <section
          class="min-h-screen bg-gradient-to-br transition easy-in duration-200"
          :class="{
            'from-indigo-700 to-indigo-800': home === 'easyTermos',
            'bg-co-500': home === 'complianceOnline',
          }"
        >
          <div class="container mx-auto py-40 px-0 sm:px-4">
            <a
              href="/"
              title="EasyTermos"
              class="flex items-center justify-start justify-center"
              v-if="home === 'easyTermos'"
            >
              <img src="../assets/img/logo-easytermos.svg" alt="" class="h-8" />
            </a>
            <a
              href="/"
              title="compliance online"
              class="flex items-center h-8 justify-start justify-center"
              v-if="home === 'complianceOnline'"
            >
              <img
                src="../assets/img/logo-complianceonline.svg"
                alt=""
                class="h-8"
              />
            </a>
            <div
              class="bg-white shadow-xl rounded-none sm:rounded-lg w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12 mx-auto px-4 sm:px-6 pt-5 pb-6 mt-8 mb-6"
            >
              <h1 class="text-lg text-center font-semibold mb-4 text-gray-900">
                Redefinir sua senha
              </h1>
              <form
                @submit.stop.prevent="onSubmit"
                class="space-y-4 mb-8"
                data-cy="login-form"
              >
                <label class="block">
                  <span class="text-xs text-gray-700 font-medium block mb-1"
                    >Nova senha</span
                  >
                  <input
                    id="input-password"
                    v-model="form.password"
                    type="password"
                    required
                    placeholder="Colocar Senha"
                    :state="
                      $v.form.password.$dirty ? !$v.form.password.$error : null
                    "
                    :class="{ 'border-danger': errorMessages }"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </label>
                <label class="block">
                  <span class="text-xs text-gray-700 font-medium block mb-1"
                    >Confirmação</span
                  >
                  <input
                    id="input-password-confirm"
                    v-model="form.passwordConfirm"
                    type="password"
                    required
                    placeholder="Enter password again"
                    :state="
                      $v.form.passwordConfirm.$dirty
                        ? !$v.form.passwordConfirm.$error
                        : null
                    "
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </label>
                <input
                  type="submit"
                  class="rounded shadow-lg cursor-pointer text-white w-full py-3 mt-1"
                  value="Mudar senha"
                  :class="{
                    'bg-co-500': home === 'complianceOnline',
                    'bg-indigo-700': home === 'easyTermos',
                  }"
                />
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, maxLength, sameAs } from 'vuelidate/lib/validators';
import router from '@/router';

export default {
  name: 'PasswordResetBox',
  props: {
    authKey: String,
  },
  data() {
    return {
      home: false,
      form: {
        password: '',
        passwordConfirm: '',
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  mounted() {
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    if (this.isLoggedIn) {
      // Already logged in
      this.logout({ router, slient: true });
    }
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapState('auth', ['loading']),
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    ...mapActions('auth', ['passwordReset', 'logout']),
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // Form submit logic
      this.passwordReset({
        key: this.authKey,
        password: this.form.password,
        router,
      });
    },
  },
};
</script>
