<template>
  <password-reset-box :auth-key="authKey" />
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import PasswordResetBox from '@/components/PasswordResetBox.vue';

export default {
  name: 'PasswordReset',
  components: { PasswordResetBox },
  metaInfo: {
    title: 'Reset Password',
    meta: [
      {
        name: 'description',
        content: 'Reset your password.',
      },
    ],
  },
  data() {
    return {
      authKey: '',
    };
  },
  mounted() {
    this.$store.commit('auth/clear');

    if (
      _.isEmpty(this.$route.query.key) ||
      _.isEmpty(this.$route.query.messageKey)
    ) {
      // error
      this.error({
        showType: 'toast',
        position: 'bottom-end',
        title: 'Error',
        text: 'This is not valid access. Please try again.',
      });
      this.$router.push('/login');
    } else {
      // all good
      this.authKey = this.$route.query.key;
      this.handleAuthMessageKey({ messageKey: this.$route.query.messageKey });
    }
  },
  computed: {
    ...mapState('auth', ['isPasswordResetted']),
    ...mapGetters('alert', ['errorMessages']),
  },
  methods: {
    ...mapActions('auth', ['handleAuthMessageKey']),
    ...mapActions('alert', ['error']),
  },
};
</script>
