<template>
  <div class="min-h-screen flex bg-gray-100 mt-8">
    <sidebar :page="'minha-conta'"></sidebar>
    <div class="flex flex-col w-0 flex-1">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button
          @click.stop="sidebarOpen = true"
          class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
        >
          <svg
            class="h-6 w-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
      <main class="flex-1 relative z-0 pt-2 pb-6 focus:outline-none"></main>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import sidebar from '../../components/Partials/Sidebar.vue';

import router from '@/router';

export default {
  name: 'Account',
  components: { sidebar },
  data() {
    return {
      home: '',
    };
  },
  mounted() {
    this.me({ router });
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    let url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';
  },
  metaInfo() {
    return {
      title: 'My account',
      meta: [],
    };
  },
  computed: {
    ...mapState('user', ['loading', 'user']),
  },
  methods: {
    ...mapActions('user', ['me']),
  },
};
</script>
