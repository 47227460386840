<template></template>

<script>
import { mapState } from 'vuex';
import FindPasswordBox from '@/components/FindPasswordBox.vue';

export default {
  name: 'FindPassword',
  components: { FindPasswordBox },
  metaInfo: {
    title: 'Find Password',
    meta: [
      {
        name: 'description',
        content: 'To reset your password, enter your email address.',
      },
    ],
  },
  mounted() {
    this.$store.commit('auth/clear');
  },
  computed: {
    ...mapState('auth', ['isPasswordResetRequested']),
  },
  methods: {},
};
</script>
